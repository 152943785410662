<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path
      d="M12 4C7.58149 4 4 7.60458 4 12.0516C4 16.0602 6.90961 19.3811 10.7189 20V14.3123H8.72313V12.0516H10.7189V10.9284C10.7189 9.87679 10.6648 9.08596 11.4932 8.04871C12.632 6.62751 15.6014 7.29226 15.6014 7.29226V9.3553C13.6057 9.32951 13.3609 9.4957 13.3609 10.9255V12.0487H15.4875L15.1317 14.3095H13.3609V19.9828C17.1302 19.3324 20 16.0287 20 12.0487C20 7.60458 16.4185 4 12 4Z"
    />
  </svg>
</template>
