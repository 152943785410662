<template>
  <BaseButton
    v-if="data.isFollowed"
    variant="black"
    class="min-w-28"
    @click="follow"
  >
    {{ t("followed") }}
  </BaseButton>

  <BaseButton
    v-else
    variant="secondary"
    class="min-w-28"
    @click="session ? follow() : open()"
  >
    {{ t("follow") }}
  </BaseButton>
</template>

<script lang="ts" setup>
import { Button as BaseButton } from "@ui/components/base/button";

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      follow: "Sledovat",
      followed: "Sleduji",
    },
    en: {
      follow: "Follow",
      followed: "Follow",
    },
  },
});

const { mutate } = useFollowCompanyQuery();

const { data } = await useCompanyDetailQuery();

const { data: session } = await useUserQuery();

const { open } = useLoginFragment();

const follow = () => {
  mutate(data.value.slug);
};
</script>
