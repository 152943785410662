import { useSuspenseQuery } from "~/queries/useSuspenseQuery";

export interface OfferCollaborations {
  id: number;
  name: string;
  slug: string;
}

export interface OfferLocations {
  id: number;
  compact: string;
  expanded: string;
}

export interface OfferSalary {
  maximum: number;
  measure: "hourly" | "monthly";
  minimum: number;
  currency: "CZK" | "EUR";
}

export interface OfferBenefits {
  id: number;
  name: string;
}

export interface OfferLanguages {
  name: string;
  group: number;
  level: number;
}

export interface OfferDetail {
  createdAt: string;
  cvRequired: boolean | null;
  slug: string;
  name: {
    cs: string;
    en: string | null;
  };
  description: {
    cs: string;
    en: string | null;
  };
  shifts: Array<80 | 160>;
  collaborations: Array<
    "freelance" | "remote" | "internship" | "employment" | "onsite" | "hybrid"
  >;
  locations: {
    cs: OfferLocations[];
    en: OfferLocations[];
  };
  salary: OfferSalary | null;
  benefits: {
    cs: OfferBenefits[];
    en: OfferBenefits[];
  } | null;
  seniorities: Array<"junior" | "medior" | "senior">;
  languages: {
    cs: OfferLanguages[];
    en: OfferLanguages[];
  } | null;
  skills: Array<{
    name: string;
    slug: string;
    type: "required" | "nice_to_have";
    weight: number;
  }>;
  promotionSince: string;
  promotionUntil: string;
  promotionType: "free" | "basic";
  company: {
    name: string;
    slug: string;
    type: "expansion" | "growth" | "idea" | "start";
    introduction: {
      cs: string;
      en: string | null;
    };
    logo: string;
    areas: Array<{
      id: number;
      name: {
        cs: string;
        en: string;
      };
    }>;
    verified: boolean;
  };
  breadcrumbs: Array<{
    id: number;
    name: {
      cs: string;
      en: string;
    };
    slug: {
      cs: string;
      en: string;
    };
  }>;
  hasUserLiked: boolean;
  hasUserApplied: boolean;
  additionalQuestion: {
    cs: string;
    en: string | null;
  } | null;
}

export const useOfferQuery = () => {
  const route = useRoute();
  const { locale } = useI18n();

  const queryKey = ["offer", computed(() => route.params.id)];

  return useSuspenseQuery({
    queryKey,
    queryFn: async () =>
      await $apiFetch<OfferDetail>(`/api/front/offer/${route.params.id}`, {
        params: { locale: locale.value },
      }),
  });
};
