export const useCompanyDetailQuery = async () => {
  const scope = effectScope();
  const route = useRoute();

  const offer = route.params.id ? await useOfferQuery() : undefined;

  return scope.run(async () => {
    const slug = computed(() => {
      if (route.params.slug && route.params.id && offer)
        return offer.data.value.company.slug;
      else return route.params.slug;
    });
    return await useSuspenseQuery({
      queryKey: ["company", slug.value],
      queryFn: async () => await $apiFetch(`/api/front/company/${slug.value}`),
    });
  });
};
