import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useDeleteSupplicationQuery = (slug: string) => {
  const { locale } = useI18n();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return $apiFetch(
        locale.value === "cs"
          ? `/startup/${slug}/zajem`
          : `/startup/${slug}/interest`,
        {
          method: "DELETE",
        },
      );
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["company", slug],
        }),
        queryClient.invalidateQueries({
          queryKey: ["inboxRequests"],
        }),
      ])
  });
};
