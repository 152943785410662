import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useCompanySubscribeQuery = (slug: Ref<string>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (email: string) =>
      $apiFetch(`/api/company/${slug.value}/watchdog`, {
        method: "GET",
        query: {
          email,
        },
      }),
    onSuccess: () => queryClient.invalidateQueries({
      queryKey: ["company", slug.value],
    }),
  });
};
