<template>
  <svg
    width="26"
    height="25"
    viewBox="0 0 26 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Vrstva_1" clip-path="url(#clip0_10233_356712)">
      <path
        id="Vector"
        d="M26 24.3805L15.3158 10.2923L24.8896 0H22.8018L14.3569 9.02901L7.51062 0H0L10.2097 13.4646L0 24.3805H2.21104L11.1784 14.7402L18.4894 24.3805H26ZM6.71445 1.6022L22.7734 22.7783H19.2843L3.22535 1.6022H6.71445Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_10233_356712">
        <rect width="26" height="24.3805" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
