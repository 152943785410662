import { useMutation, useQueryClient } from "@tanstack/vue-query";

export const useSupplicationQuery = (slug: string) => {
  const { locale } = useI18n();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ email, gdpr, why, areas }) => {
      return $apiFetch(
        locale.value === "cs"
          ? `/api/startup/${slug}/zajem`
          : `/api/startup/${slug}/interest`,
        {
          method: "POST",
          body: {
            payload: {
              email,
              gdpr,
              why,
              areas,
            },
          },
        },
      );
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["company", slug],
        }),
        queryClient.invalidateQueries({
          queryKey: ["inboxRequests"],
        }),
      ])
  });
};
