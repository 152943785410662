<template>
  <BaseButton v-if="false" variant="black" class="w-full" @click="store.remove">
    {{ isDesktop ? t("sent.desktop") : t("sent.mobile") }}
  </BaseButton>
  <BaseButton v-else class="w-full" @click="store.open">
    <template v-if="store.meta.dirty">
      {{ isDesktop ? t("continue.desktop") : t("continue.mobile") }}
    </template>

    <template v-else>
      {{ isDesktop ? t("lead.desktop") : t("lead.mobile") }}
    </template>
  </BaseButton>
</template>

<script lang="ts" setup>
import { Button as BaseButton } from "@ui/components/base/button";

const route = useRoute();
const offer = route.params.id ? await useOfferQuery() : undefined;
const { data: session } = await useUserQuery();

const companySlug = computed(() => {
  if (route.params.slug && route.params.id && offer)
    return offer.data.value.company.slug;
  else return route.params.slug;
});
const store = useSupplicationStore(companySlug.value, session.value?.username);
const isDesktop = useIsDesktop();

const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      lead: {
        mobile: "Chci tu pracovat",
        desktop: "Líbilo by se mi tu pracovat",
      },
      sent: {
        mobile: "Zájem odeslán",
        desktop: "Zájem odeslán, kontaktovat firmu",
      },
      continue: {
        mobile: "Pokračovat",
        desktop: "Pokračovat ve vyplňování",
      },
    },
    en: {
      lead: {
        mobile: "I want to work here",
        desktop: "I would enjoy working here",
      },
      sent: {
        mobile: "Interest sent",
        desktop: "Interest sent, contact the company",
      },
      continue: {
        mobile: "Continue",
        desktop: "Continue filling out",
      },
    },
  },
});
</script>
