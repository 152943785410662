import { useForm } from "vee-validate";
import { object, string, boolean, array } from "yup";
import { toTypedSchema } from "@vee-validate/yup";

export const useSupplicationStore = (slug: string, username?: string) => {
  return defineStore(`supplication-${slug}`, () => {
    const { isOpen, close, open } = useSupplicationFragment();

    const { mutate } = useSupplicationQuery(slug);
    const { mutate: deleteMutate } = useDeleteSupplicationQuery(slug);

    const validationSchema = toTypedSchema(
      object({
        email: string()
          .matches(/^[\w-\.]+(\+[\w-]+)?@([\w-]+\.)+[\w-]{2,9}$/, {
            message: "invalid_email",
          })
          .required("required")
          .default(username ?? ""),
        areas: array()
          .of(object({ id: string().required() }))
          .default([]),
        reason: string().required("required").default(""),
        agreement: boolean().default(false),
      }),
    );

    const { meta, handleSubmit, resetForm, defineField } = useForm({
      validationSchema,
    });

    const [email, emailAttrs] = defineField("email", { validateOnBlur: true });
    const [areas, areasAttrs] = defineField("areas", { validateOnBlur: true });
    const [reason, reasonAttrs] = defineField("reason", {
      validateOnBlur: true,
    });
    const [agreement, agreementAttrs] = defineField("agreement");

    const emailError = useFieldError("email");
    const areasError = useFieldError("areas");
    const reasonError = useFieldError("reason");

    const submit = handleSubmit(async (values) => {
      const payload = {
        email: values.email,
        gdpr: values.agreement,
        why: values.reason,
        areas: values.areas.map(({ id }) => id),
      };

      mutate(payload, {
        onSuccess: async () => {
          resetForm();
          return true;
        },
        onError: () => {
          return false;
        },
      });
    });

    const remove = () => {
      deleteMutate();
    };

    return {
      email,
      emailAttrs,
      emailError,
      areas,
      areasAttrs,
      areasError,
      reason,
      reasonAttrs,
      reasonError,
      agreement,
      submit,
      remove,
      meta,
      isOpen,
      close,
      open,
    };
  })();
};
