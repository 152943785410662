<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_7939_331210)">
      <path
        d="M7.66694 0.667969H20.3336C20.3336 0.667969 20.4098 0.677493 20.4384 0.687016C20.8003 0.734635 21.1622 0.763207 21.505 0.839397C22.7717 1.10606 23.886 1.66797 24.8384 2.53464C26.2098 3.76321 27.0193 5.28702 27.2765 7.10606C27.2955 7.29654 27.3146 7.47749 27.3336 7.66797V20.3346C27.3336 20.3346 27.3336 20.4108 27.3146 20.4394C27.286 20.668 27.286 20.8965 27.2384 21.1156C27.0003 22.5251 26.4193 23.7918 25.4574 24.8489C24.0193 26.4489 22.2098 27.3156 20.0669 27.3251C16.0384 27.3632 12.0098 27.3442 7.99075 27.3442C7.40027 27.3442 6.8098 27.2584 6.23837 27.1061C5.07646 26.8013 4.03837 26.2584 3.15266 25.4489C1.55266 23.9918 0.695512 22.1823 0.685989 20.0203C0.657417 16.0108 0.676465 11.9823 0.685989 7.97273C0.685989 7.56321 0.724084 7.15368 0.800274 6.74416C1.11456 5.02035 1.94313 3.58225 3.27646 2.4394C4.38123 1.48702 5.66694 0.915588 7.12408 0.734635C7.30504 0.706064 7.48599 0.687016 7.66694 0.667969ZM14.0003 21.4013C18.0955 21.4013 21.4003 18.0965 21.4098 14.0013C21.4098 9.92511 18.1146 6.6394 14.086 6.59178C9.95266 6.54416 6.59075 9.89654 6.59075 13.9918C6.59075 18.087 9.90504 21.4013 13.9908 21.4013H14.0003ZM21.4098 5.11559C21.4098 5.93464 22.086 6.61083 22.8955 6.59178C23.705 6.59178 24.3717 5.91559 24.3812 5.10606C24.3812 4.29654 23.705 3.62035 22.8955 3.62035C22.0765 3.62035 21.4003 4.29654 21.4098 5.10606V5.11559Z"
        fill="currentColor"
      />
      <path
        d="M13.9996 9.55469C16.4472 9.55469 18.4472 11.5452 18.4472 14.0023C18.4472 16.4594 16.4472 18.4499 13.9996 18.4499C11.552 18.4499 9.56152 16.4499 9.56152 14.0023C9.56152 11.5452 11.552 9.55469 13.9996 9.55469Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7939_331210">
        <rect
          width="26.6667"
          height="26.6667"
          fill="currentColor"
          transform="translate(0.666992 0.667969)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
